@mixin default-theme {
  :root.gr-theme-default {
    @content;
  }
}

@mixin kst-theme {
  :root.gr-theme-kst {
    @content;
  }
}

@mixin test-theme {
  :root.gr-theme-test {
    @content;
  }
}

@mixin gr-theme {
  :root.gr-theme-gr {
    @content;
  }
}
