@import "./theming";

html {
  font-family: "Helvetica Neue";
}

h1,
h2,
h3 {
  margin: 0 0 16px 0;
}

.gr-headline,
h1 {
  @include gr-typography-level-to-styles(headline);
}

.gr-lead,
h2 {
  @include gr-typography-level-to-styles(lead);
}

.gr-lead-bold,
h3 {
  @include gr-typography-level-to-styles(lead-bold);
}

p {
  margin: 0 0 16px 0;
}

.gr-paragraph,
p {
  @include gr-typography-level-to-styles(paragraph);
}

.gr-paragraph-bold {
  @include gr-typography-level-to-styles(paragraph-bold);
}

.gr-paragraph-s {
  @include gr-typography-level-to-styles(paragraph-s);
}

.gr-paragraph-s-bold {
  @include gr-typography-level-to-styles(paragraph-s-bold);
}

.gr-paragraph-xs-bold {
  @include gr-typography-level-to-styles(paragraph-xs-bold);
}
