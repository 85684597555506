// Utility for fetching a nested value from a typography config.
@function _gr-get-type-value($level, $name) {
  @return map-get(map-get($typography, $level), $name);
}

// Gets the font family for a level inside a typography config.
@function gr-font-family($level) {
  @return _gr-get-type-value($level, font-family);
}

// Gets the font size for a level inside a typography config.
@function gr-font-size($level) {
  @return _gr-get-type-value($level, font-size);
}

// Gets the line height for a level inside a typography config.
@function gr-line-height($level) {
  @return _gr-get-type-value($level, line-height);
}

// Gets the font weight for a level inside a typography config.
@function gr-font-weight($level) {
  @return _gr-get-type-value($level, font-weight);
}

// Gets the letter spacing for a level inside a typography config.
@function gr-letter-spacing($level) {
  @return _gr-get-type-value($level, letter-spacing);
}

// Converts a typography level into CSS styles.
@mixin gr-typography-level-to-styles($level) {
  $font-family: gr-font-family($level);
  $font-size: gr-font-size($level);
  $font-weight: gr-font-weight($level);
  $line-height: gr-line-height($level);
  $letter-spacing: gr-letter-spacing($level);

  $variable: --gr-typography-#{$level};
  $font: $font-weight #{$font-size}/#{$line-height} $font-family;

  font: var(#{$variable}, $font);

  letter-spacing: var(
    --gr-typography-letter-spacing-#{$level},
    $letter-spacing
  );
}
