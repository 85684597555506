@import "./mixins/@bundle";

// Structure
// --------------------------------------------------
// Adds structural css to the native html elements

* {
  box-sizing: border-box;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

html {
  width: 100%;
  height: 100%;

  text-size-adjust: 100%;
}

html:not(.hydrated) body {
  display: none;
}

html.plt-pwa {
  height: 100vh;
}

body {
  @include font-smoothing();

  position: relative;

  max-height: none;
  width: 100%;
  max-width: 100%;

  overflow: auto;

  background: #{gr-color(surface-300)};

  margin: 0;

  padding: 0;

  text-rendering: optimizeLegibility;

  touch-action: manipulation;

  -webkit-user-drag: none;

  -ms-content-zooming: none;

  word-wrap: break-word;

  overscroll-behavior-y: none;

  text-size-adjust: none;

  -ms-overflow-style: none;

  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

hr {
  height: 0;

  margin: 0 0 16px 0;

  border: 0;
  border-top: 1px solid #{gr-color(stroke-100)};
}
