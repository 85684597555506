@import "./theming";

body {
  /**
    * @prop --gr-background-color: Default background color
    * @prop --gr-color: Default color
    * @prop --base-border-color: Default border color
    * @prop --base-border-radius-s: Default broder radius small
    * @prop --base-border-radius-m: Default broder radius medium
    * @prop --base-border-width: Default border width
    * @prop --base-backdrop-background-color: Default backdrop background color
    */
  --gr-background-color: #{gr-color(surface-400)};
  --gr-color: #{gr-color(stroke-600)};
  --gr-font-family: "Helvetica Neue";
  --base-border-color: #{gr-color(stroke-100)};
  --base-border-radius-s: 4px;
  --base-border-radius-m: 8px;
  --base-border-width: 1px;
  --base-backdrop-background-color: rgba(0, 0, 0, 0.4);

  @each $color-name, $value in $colors {
    .gr-color-#{$color-name} {
      --gr-color-highlight: #{gr-color($color-name)};
    }
  }
}
