@include gr-theme {
  --gr-color-organization-10: #EDEDFA;
  --gr-color-organization-40: #9393E2;
  --gr-color-organization-60: #3C2F87;
  --gr-color-organization-80: #3C2F87;

  m2o-badge {
    color: #000;
  }

  m2o-navigation-accordion-item {
    --gr-color-stroke-600: #fff;
  }

  m2o-item-list {
    --color-hover: #000;
  }

  .navigation-item-wrapper {
    --gr-color-stroke-600: #fff;
  }

  .config-navigation-title {
    color: #fff;
  }

  .navigation-timetable-title {
    color: #fff;
  }

  .navigation-item-badged-icon {
    color: #000;
  }

  .header-navigation {
    &__title {
      color: #fff;
    }

    &__back__icon {
      color: #fff;
    }
  }

  .item-list-wrapper {
    background: var(--gr-color-organization-10);
  }
}
